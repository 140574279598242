import { IconPlus } from "icons";
import styles from "./AddProductItem.module.scss";

export const AddProductItem = ({ index, product }) => {
  return (
    <div className={styles.container}>
      <div className={styles.order}>{index}</div>
      {!product && <IconPlus />}
    </div>
  );
};
