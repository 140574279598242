import React from "react";
import styles from "./ScrollToTopButton.module.scss";
import { IconScrollToTop } from "icons";

export const ScrollToTopButton = ({ isScrolled }) => {
  if (!isScrolled) {
    return null;
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <button onClick={scrollToTop} className={styles.scrollToTopButton}>
      <IconScrollToTop />
    </button>
  );
};
