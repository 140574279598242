import React from "react";
import styles from "./TransparentDialog.module.scss";
import { IconDarkClose } from "icons";

export const TransparentDialog = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <button className={styles.closeButton} onClick={onClose}>
          <IconDarkClose color='#000' />
        </button>
        {children}
      </div>
    </div>
  );
};
