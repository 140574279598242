import styles from "./InformativeBlock.module.scss";
import { IconRectangleLine } from "icons";

export const InformativeBlock = ({ text, name }) => {
  return (
    <div className={styles.container}>
      {!!name && <h3 className={styles.title}>{name}</h3>}
      <IconRectangleLine />
      <p className={styles.text}>{text}</p>
    </div>
  );
};
