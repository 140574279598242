import React from "react";
import { Button, Tooltip, tooltipClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
import styles from "./TooltipLikePopover.module.scss";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "20px",
    fontWeight: 500,
    border: "none",
    display: "flex",
    flexDirection: "column",
    padding: 32,
    rowGap: 24,
    borderRadius: 8,
  },
}));

export const TooltipLikePopover = ({
  anchorElement,
  children,
  anchorClassName,
  contentClassName,
  ...props
}) => {
  const tooltipContent = <React.Fragment>{children}</React.Fragment>;

  return (
    <HtmlTooltip
      className={`${styles.popoverContent} ${contentClassName}`}
      title={tooltipContent}
      {...props}
    >
      <Button className={anchorClassName}>{anchorElement}</Button>
    </HtmlTooltip>
  );
};
