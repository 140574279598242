import styles from "./ConfiguratorLine.module.scss";
import { IconConfiguratorOnLine } from "icons";

export const ConfiguratorLine = ({ onClickItem = () => {} }) => {
  return (
    <div onClick={onClickItem} className={styles.container}>
      <div className={styles.wordBlock}>
        <IconConfiguratorOnLine />
      </div>
    </div>
  );
};
