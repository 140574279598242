import {
  IconStarFilled,
  IconDoubleTickFilled,
  IconDiamondFilled,
  IconSettingFilled,
  IconWavedLampFilled,
  IconPrecisionFilled,
} from "icons";
import ImageAviation from "assets/images/market-sector/aviation.png";
import ImageMedical from "assets/images/market-sector/medical.png";
import ImageContractManufacturing from "assets/images/market-sector/contract-manufacturing.png";
import Pilot from "assets/images/products/pilot.png";
import JadeMK2 from "assets/images/products/jade-mk2.png";
import QuadronPLC from "assets/images/products/quadron-plc.png";

export const EXISTING_MARKET_SECTOR_KEYS = {
  "Automotive/EV": "Automotive/EV",
};

export const INFOS = [
  "From battery management systems to power electronics and beyond, we offer comprehensive soldering services for a wide range of EV applications. Whether you require prototype development, low-volume production, or high-volume manufacturing, we have the capabilities and resources to accommodate your needs.",
  "As a trusted partner to leading EV manufacturers and suppliers, we are committed to excellence in every aspect of our work. With a focus on innovation, quality, and customer satisfaction, we strive to exceed expectations and drive success in the rapidly evolving EV market.",
  "Introducing Pillarhouse, your trusted partner in soldering excellence for the electric vehicle (EV) industry. As pioneers in soldering technology, we specialize in providing top-tier soldering services tailored specifically for EV manufacturers.",
];

export const SYSTEM_OPTIONS = [
  "Top side IR preheat, with closed-loop control",
  "Motorised underside IR preheat, at all cell positions",
  "Ultrasonic fluxing",
  "Dual Drop-Jet / Ultrasonic fluxing",
];

export const MONITORING_OPTIONS = [
  "Flux spray monitoring",
  "Flux flow monitoring",
  "Flux spray & flow monitoring",
];

export const CHOOSE_US_ITEMS = [
  {
    icon: <IconStarFilled />,
    title: "Expertise",
    text: "Experience meets innovation in Pillarhouse, your go-to partner for cutting-edge solutions in the selective soldering industry. With roots deeply embedded in the automotive sector, we've been trusted by major EV and traditional automakers worldwide.",
  },
  {
    icon: <IconPrecisionFilled />,
    title: "Precision",
    text: "Powered by state-of-the-art process control technology, we ensure a robust manufacturing process that stands the test of time, from inception to mature production.",
  },
  {
    icon: <IconDoubleTickFilled />,
    title: "Efficiency",
    text: "Efficiency is at the core of what we do. Whether you opt for our modular or cellular solutions, rest assured, we have the perfect streamlined system tailored to your exact requirements.",
  },
  {
    icon: <IconWavedLampFilled />,
    title: "Innovation",
    text: "At Pillarhouse, innovation isn't just a buzzword – it's our ethos. With 'Expect Innovation' as our tagline, we're dedicated to delivering on that promise. As the largest market share 'pure play' selective manufacturer, we channel all our resources into driving process advancements that set industry standards.",
  },
  {
    icon: <IconDiamondFilled />,
    title: "Quality",
    text: "With multiple factories and mature manufacturing processes, Pillarhouse guarantees the highest standard of machinery and processes, ensuring reliability and longevity.",
  },
  {
    icon: <IconSettingFilled />,
    title: "Customization",
    text: "With a keen ear to your needs, Pillarhouse offers tailored solutions that are both agile and creative. Explore our diverse range of platforms, each uniquely suited to elevate your industry standards. Trust Pillarhouse for unmatched expertise, precision, efficiency, innovation, quality, and customization – because your success is our priority.",
  },
];

export const OTHER_MARKETS = [
  {
    name: "Medical",
    forwardTo: "/market?name=Medical",
    description:
      "Entry-level, benchtop, single point selective soldering system",
    content: ImageMedical,
  },
  {
    name: "Aerospace",
    forwardTo: "/market?name=Aerospace",
    description:
      "Elementary, flexible, single point selective soldering system",
    content: ImageAviation,
  },
  {
    name: "Contract Manufacturing",
    forwardTo: "/market?name=Hi-Rel%2FInfrastructure",
    description:
      "Enhanced, entry-level, single point selective soldering system",
    content: ImageContractManufacturing,
  },
];

export const RELATED_PRODUCTS = [
  {
    name: "Pilot",
    forwardTo: "/product?name=pilot",
    description:
      "Entry-level, benchtop, single point selective soldering system",
    content: Pilot,
  },
  {
    name: "JADE MKII",
    forwardTo: "/product?name=JADE MKII",
    description:
      "Elementary, flexible, single point selective soldering system",
    content: JadeMK2,
  },
  {
    name: "QUADRON (PLC)",
    forwardTo: "/product?name=Quadron",
    description: "High specification, PLC, rotary soldering machine",
    content: QuadronPLC,
  },
];
