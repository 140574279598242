import { useTranslation } from "hooks/useTranslation";
import { IconDarkClose } from "icons";
import styles from "./Locations.module.scss";
import { LOCATIONS } from "constants";
import { LocationCard } from "shared/components/LocationCard";
import { RowListItem } from "shared/components/RowListItem";

const PRIMARY_LOCATIONS = LOCATIONS.slice(0, 4);
const ALL_LOCATIONS = LOCATIONS.slice(4);

export const Locations = ({ hasCloseIcon, handleClose }) => {
  const { translate } = useTranslation();
  return (
    <div className={styles.container}>
      {!!hasCloseIcon && (
        <div onClick={handleClose} className={styles.closeIconContainer}>
          <IconDarkClose />
        </div>
      )}
      <div className={styles.leftSection}>
        <div className={styles.primaryLocationsBlock}>
          {PRIMARY_LOCATIONS.map(location => (
            <LocationCard key={location.id} {...location} />
          ))}
        </div>
        <div className={styles.allLocationsBlock}>
          {ALL_LOCATIONS.map(location => (
            <RowListItem key={location.id} {...location} />
          ))}
        </div>
      </div>
      <div className={styles.verticalLine}></div>
      <div className={styles.rightSection}>
        <div className={styles.titleContainer}>
          <h4 className={styles.title}>{translate("Worldwide")}</h4>
          <h4 className={`${styles.title} ${styles.red}`}>
            {translate("Locations")}
          </h4>
        </div>
        <p className={styles.description}>
          {translate(
            "Pillarhouse was founded in 1978, by current Chairman Alex Ciniglio. Pillarhouse is a company with a strong bias towards innovative design and development. Supplying specialist equipment to the coil winding industry. Pillarhouse produced its first production soldering system in 1984. With continued growth throughout the 1980’s, Pillarhouse introduced the World’s first selective soldering machine in 1994."
          )}
        </p>
      </div>
    </div>
  );
};
