import React, { useState } from "react";
import styles from "./VerticalCounter.module.scss";

export const VerticalCounter = ({
  handleIncrement,
  handleDecrement,
  outerCount = 1,
  isDisabledActions,
}) => {
  const [count, setCount] = useState(outerCount);

  const increment = () => {
    if (handleIncrement) {
      handleIncrement();
    } else {
      setCount(count + 1);
    }
  };

  const decrement = () => {
    if (handleDecrement) {
      handleDecrement();
    } else {
      if (count > 1) {
        setCount(count - 1);
      }
    }
  };

  return (
    <div className={styles.counterContainer}>
      <button
        disabled={isDisabledActions}
        className={styles.counterButton}
        onClick={increment}
      >
        +
      </button>
      <button disabled={isDisabledActions} className={styles.counterDisplay}>
        {outerCount || outerCount === 0 ? outerCount : count}
      </button>
      <button
        disabled={
          isDisabledActions || (outerCount ? outerCount === 1 : count === 1)
        }
        className={styles.counterButton}
        onClick={decrement}
      >
        -
      </button>
    </div>
  );
};
