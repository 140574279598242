import React from "react";
import TextField from "@mui/material/TextField";
import styles from "./MainInput.module.scss";
import { IconWarning } from "icons";

export const MainInputInput = ({
  error,
  helperText,
  hasErrorIcon,
  onEnter,
  styleType = "dark",
  ...props
}) => {
  const handleKeyDown = e => {
    if (e.key === "Enter" && onEnter) {
      onEnter();
    }
  };

  return (
    <div className={styles.inputContainer}>
      <TextField
        variant="outlined"
        error={error}
        helperText={error ? "" : helperText}
        fullWidth
        className={styles.input}
        sx={{
          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#404040 !important",
            outlineColor: "#404040 !important",
          },
        }}
        inputProps={{
          className: `${styles.mainInputElm} ${error && styles.hasError} ${
            styles[styleType]
          }`,
          onKeyDown: handleKeyDown,
        }}
        {...props}
      />
      {error && (
        <div className={styles.errorContainer}>
          {hasErrorIcon && (
            <span className={styles.errorIconContainer}>
              <IconWarning />
            </span>
          )}
          <span className={styles.errorText}>{helperText}</span>
        </div>
      )}
    </div>
  );
};
