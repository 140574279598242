import Fusion4Image from "assets/images/products/fusion-4.png";
import CarImage from "assets/images/market-sector/banner-market-sector-img.png";
import BannerImg1 from "assets/images/market-sector/market-banner-1.png";
import BannerImg2 from "assets/images/market-sector/market-banner-2.png";
import BannerImg3 from "assets/images/market-sector/market-banner-3.png";

export const BANNER_DETAILS = {
  default: [
    {
      details: {
        Installations: "800+",
        "Worked Hours": "4.5k+",
        "Produced Boards": "2k+",
      },
      title: "Orissa Synchrodex Pro Combo",
      backgroundImg: Fusion4Image,
    },
    {
      details: {
        Installations: "40+",
        Effectiveness: "97%",
        "Market Share": "28%",
      },
      titlePrefix: "Market Sector",
      title: "Electric Vehicles",
      backgroundImg: CarImage,
    },
    {
      details: {
        Installations: "800+",
        "Worked Hours": "4.5k+",
        "Produced Boards": "2k+",
      },
      title: "Orissa Synchrodex Pro Combo",
      backgroundImg: Fusion4Image,
    },
  ],
  market: [
    {
      details: {
        Installations: "800+",
        "Worked Hours": "4.5k+",
        "Produced Boards": "2k+",
      },
      title: "Orissa Synchrodex Pro Combo",
      backgroundImg: BannerImg1,
    },
    {
      details: {
        Installations: "40+",
        Effectiveness: "97%",
        "Market Share": "28%",
      },
      titlePrefix: "Market Sector",
      title: "Electric Vehicles",
      backgroundImg: BannerImg2,
    },
    {
      details: {
        Installations: "800+",
        "Worked Hours": "4.5k+",
        "Produced Boards": "2k+",
      },
      title: "Orissa Synchrodex Pro Combo",
      backgroundImg: BannerImg3,
    },
  ],
};

export const BANNER_TXT_DETAILS = {
  market: {
    details: {
      Installations: "40+",
      Effectiveness: "97%",
      "Market Share": "28%",
    },
    titlePrefix: "Market Sector",
    title: "Electric Vehicles",
  },
  product: {
    details: {
      Installations: "800+",
      "Worked Hours": "4.5k+",
      "Produced Boards": "2k+",
    },
    title: "Orissa Synchrodex Pro Combo",
  },
};
