import { useTranslation } from "hooks/useTranslation";
import styles from "./CellItem.module.scss";
import { IconEmptyCell } from "icons";

const iconsSize = {
  xs: {
    width: "20",
    height: "20",
  },
  md: {
    width: "40",
    height: "40",
  },
};

export const CellItem = props => {
  const { translate } = useTranslation();
  const { cell, size = "md", hasHoverState, className } = props;
  return (
    <div
      className={`${styles.container} ${styles[size]} ${className} ${
        hasHoverState && styles.hasHoverState
      }`}
    >
      {cell && !cell?.isEmpty ? (
        <img alt={cell.name} src={cell.image} className={styles.img} />
      ) : (
        <>
          <IconEmptyCell
            className={styles.emptyIcon}
            {...(iconsSize[size] || {})}
          />
          <p className={styles.text}>
            {translate(cell?.isEmpty ? "Empty" : "Not chosen")}
          </p>
        </>
      )}
    </div>
  );
};
