import { Link, useNavigate, useParams } from "react-router-dom";
import queryString from "query-string";

export const DynamicLink = ({ to, queryParams, onClick, ...props }) => {
  const { company } = useParams();
  const navigate = useNavigate();

  const basePath = company ? `/${company}${to}` : to;

  const queryStringified = queryParams
    ? `?${queryString.stringify(queryParams)}`
    : "";
  const fullPath = `${basePath}${queryStringified}`;

  const handleClick = event => {
    event.preventDefault();
    window.scrollTo(0, 0);
    if (typeof onClick === "function") {
      onClick(event);
    }
    const basePath = company ? `/${company}${to}` : to;
    const queryStringified = queryParams
      ? `?${queryString.stringify(queryParams)}`
      : "";
    const fullPath = `${basePath}${queryStringified}`;
    navigate(fullPath);
  };

  return <Link to={fullPath} onClick={e => handleClick(e)} {...props} />;
};
