import ProductImage from "assets/images/products/productImg.png";
import DetailImg from "assets/images/products/productDetailImg.png";
import CellImageHTop from "assets/images/configurator/cells/H-top.png";
import CellImageHBottom from "assets/images/configurator/cells/H-bottom.png";
import CellImageF from "assets/images/configurator/cells/F.png";
import CellImageWS from "assets/images/configurator/cells/WS.png";
import CellImageHH from "assets/images/configurator/cells/HH.png";
import CellImageS from "assets/images/configurator/cells/S.png";
import CellImageHS from "assets/images/configurator/cells/HS.png";
import CellImageMD from "assets/images/configurator/cells/MD.png";
import CellImageHWS from "assets/images/configurator/cells/HWS.png";
import CellImageHF from "assets/images/configurator/cells/HF.png";
import CellImageHFF from "assets/images/configurator/cells/HFF.png";
import CellImageHMD from "assets/images/configurator/cells/HMD.png";
import CellImageHSS from "assets/images/configurator/cells/HSS.png";
import CellImageHHFF from "assets/images/configurator/cells/HHFF.png";
import CellImageHJW from "assets/images/configurator/cells/HJW.png";
import CellImageHHJW from "assets/images/configurator/cells/HHJW.png";
import CellImageHDSS from "assets/images/configurator/cells/HDSS.png";
import { IconLikeCompass, IconMonitor } from "icons";

export const STANDARD_FEATURES_ICONS = {
  iconMonitor: <IconMonitor width="32" height="32" color="#000" />,
  iconLikeCompass: <IconLikeCompass />,
};

export const EMPTY_PRODUCT_CELL = {
  id: 100,
  name: "Empty",
  with: "No Device",
  isEmpty: true,
};

export const PRODUCT_CELLS = [
  {
    id: 1,
    name: "Preheat Module",
    with: "with bottom preheating",
    backend_name: "HB",
    description:
      "This compact modular in-line system ulitises the same design concept as the new generation Synchrodex Pro soldering cell and offers the user significantly reduced process time when compared to using a single soldering unit with on-board fluxing and preheat functions.",
    image: CellImageHBottom,
  },
  {
    id: 2,
    name: "Flux Module",
    backend_name: "F",
    with: "with spray fluxer",
    description:
      "This compact modular in-line system ulitises the same design concept as the new generation Synchrodex Pro soldering cell and offers the user significantly reduced process time when compared to using a single soldering unit with on-board fluxing and preheat functions.",
    image: CellImageF,
  },
  {
    id: 3,
    name: "Solder Module",
    backend_name: "WS",
    with: "with multi wave module",
    description:
      "This compact modular in-line system ulitises the same design concept as the new generation Synchrodex Pro soldering cell and offers the user significantly reduced process time when compared to using a single soldering unit with on-board fluxing and preheat functions.",
    image: CellImageWS,
  },
  {
    id: 4,
    name: "Preheat Module",
    with: "with top and bottom preheating",
    backend_name: "HH",
    description:
      "This compact modular in-line system ulitises the same design concept as the new generation Synchrodex Pro soldering cell and offers the user significantly reduced process time when compared to using a single soldering unit with on-board fluxing and preheat functions.",
    image: CellImageHH,
  },
  {
    id: 5,
    name: "Solder Module",
    with: "with single pot",
    backend_name: "S",
    image: CellImageS,
  },
  {
    id: 6,
    name: "Solder Module",
    with: "with single pot and preheating",
    backend_name: "HTS",
    description:
      "This compact modular in-line system ulitises the same design concept as the new generation Synchrodex Pro soldering cell and offers the user significantly reduced process time when compared to using a single soldering unit with on-board fluxing and preheat functions.",
    image: CellImageHS,
  },
  {
    id: 7,
    name: "Preheat Module",
    with: "with top side preheating",
    backend_name: "HT",
    description:
      "This compact modular in-line system ulitises the same design concept as the new generation Synchrodex Pro soldering cell and offers the user significantly reduced process time when compared to using a single soldering unit with on-board fluxing and preheat functions.",
    image: CellImageHTop,
  },
  {
    id: 8,
    name: "Solder Module",
    with: "with multi wave module",
    backend_name: "MD",
    description:
      "This compact modular in-line system ulitises the same design concept as the new generation Synchrodex Pro soldering cell and offers the user significantly reduced process time when compared to using a single soldering unit with on-board fluxing and preheat functions.",
    image: CellImageMD,
  },
  {
    id: 9,
    name: "Solder Module",
    with: "",
    backend_name: "HTWS",
    description:
      "This compact modular in-line system ulitises the same design concept as the new generation Synchrodex Pro soldering cell and offers the user significantly reduced process time when compared to using a single soldering unit with on-board fluxing and preheat functions.",
    image: CellImageHWS,
  },
  {
    id: 10,
    name: "Flux Module",
    with: "with spray fluxer and preheating",
    backend_name: "HTF",
    description:
      "This compact modular in-line system ulitises the same design concept as the new generation Synchrodex Pro soldering cell and offers the user significantly reduced process time when compared to using a single soldering unit with on-board fluxing and preheat functions.",
    image: CellImageHF,
  },
  {
    id: 11,
    name: "Flux Module",
    with: "with dual pots spray fluxer and preheating",
    backend_name: "HTFF",
    description:
      "This compact modular in-line system ulitises the same design concept as the new generation Synchrodex Pro soldering cell and offers the user significantly reduced process time when compared to using a single soldering unit with on-board fluxing and preheat functions.",
    image: CellImageHFF,
  },
  {
    id: 13,
    name: "Solder Module",
    with: "with multi wave module and preheating",
    backend_name: "HTMD",
    description:
      "This compact modular in-line system ulitises the same design concept as the new generation Synchrodex Pro soldering cell and offers the user significantly reduced process time when compared to using a single soldering unit with on-board fluxing and preheat functions.",
    image: CellImageHMD,
  },
  {
    id: 14,
    name: "Solder Module",
    with: "with dual independant pots and preheating",
    backend_name: "HTSS",
    description:
      "This compact modular in-line system ulitises the same design concept as the new generation Synchrodex Pro soldering cell and offers the user significantly reduced process time when compared to using a single soldering unit with on-board fluxing and preheat functions.",
    image: CellImageHSS,
  },
  {
    id: 15,
    name: "Flux Module",
    backend_name: "HHFF",
    with: "",
    description:
      "This compact modular in-line system ulitises the same design concept as the new generation Synchrodex Pro soldering cell and offers the user significantly reduced process time when compared to using a single soldering unit with on-board fluxing and preheat functions.",
    image: CellImageHHFF,
  },
  {
    id: 16,
    name: "Solder Module",
    backend_name: "HTJW",
    with: null,
    description:
      "This compact modular in-line system ulitises the same design concept as the new generation Synchrodex Pro soldering cell and offers the user significantly reduced process time when compared to using a single soldering unit with on-board fluxing and preheat functions.",
    image: CellImageHJW,
  },
  {
    id: 17,
    name: "Solder Module",
    backend_name: "HHJW",
    with: null,
    description:
      "This compact modular in-line system ulitises the same design concept as the new generation Synchrodex Pro soldering cell and offers the user significantly reduced process time when compared to using a single soldering unit with on-board fluxing and preheat functions.",
    image: CellImageHHJW,
  },
  {
    id: 18,
    name: "Solder Module",
    backend_name: "HTDSS",
    with: "with dual pots and preheating",
    description:
      "This compact modular in-line system ulitises the same design concept as the new generation Synchrodex Pro soldering cell and offers the user significantly reduced process time when compared to using a single soldering unit with on-board fluxing and preheat functions.",
    image: CellImageHDSS,
  },
];

export const PRODUCTS = [
  {
    name: "Synchrodex Solder",
    id: 1,
    img: ProductImage,
    detailImg: DetailImg,
    cells: [{ id: 1, img: null, name: null }],
    options: [
      {
        id: 1,
        name: "Units Per Hour",
        measurementUnit: "7.68 u/h",
        progress: 80,
      },
      {
        id: 2,
        name: "Fluxes Per Hour",
        measurementUnit: "12.7 f/m",
        progress: 60,
      },
      { id: 3, name: "Inclination", measurementUnit: "58.67°", progress: 65 },
      { id: 4, name: "Start Time", measurementUnit: "1m 04s", progress: 10 },
      {
        id: 5,
        name: "Defects Per 100 Unit",
        measurementUnit: "2/100 d",
        progress: 10,
      },
      { id: 6, name: "Energy Class", measurementUnit: "A++", progress: 100 },
    ],
  },
  {
    name: "Synchrodex Single Solder",
    id: 2,
    img: ProductImage,
    detailImg: DetailImg,
    cells: [{ id: 1, img: null, name: null }],
    options: [
      {
        id: 1,
        name: "Units Per Hour",
        measurementUnit: "7.68 u/h",
        progress: 80,
      },
      {
        id: 2,
        name: "Fluxes Per Hour",
        measurementUnit: "12.7 f/m",
        progress: 60,
      },
      { id: 3, name: "Inclination", measurementUnit: "58.67°", progress: 65 },
      { id: 4, name: "Start Time", measurementUnit: "1m 04s", progress: 10 },
      {
        id: 5,
        name: "Defects Per 100 Unit",
        measurementUnit: "2/100 d",
        progress: 10,
      },
      { id: 6, name: "Energy Class", measurementUnit: "A++", progress: 100 },
    ],
  },
  {
    name: "Synchrodex Twin Solder",
    id: 3,
    img: ProductImage,
    detailImg: DetailImg,
    cells: [{ id: 1, img: null, name: null }],
    options: [
      {
        id: 1,
        name: "Units Per Hour",
        measurementUnit: "7.68 u/h",
        progress: 80,
      },
      {
        id: 2,
        name: "Fluxes Per Hour",
        measurementUnit: "12.7 f/m",
        progress: 60,
      },
      { id: 3, name: "Inclination", measurementUnit: "58.67°", progress: 65 },
      { id: 4, name: "Start Time", measurementUnit: "1m 04s", progress: 10 },
      {
        id: 5,
        name: "Defects Per 100 Unit",
        measurementUnit: "2/100 d",
        progress: 10,
      },
      { id: 6, name: "Energy Class", measurementUnit: "A++", progress: 100 },
    ],
  },
  {
    name: "Fusion 3",
    id: 4,
    img: ProductImage,
    detailImg: DetailImg,
    cells: [
      { id: 1, img: null, name: null },
      { id: 2, img: null, name: null },
      { id: 3, img: null, name: null },
    ],
    options: [
      {
        id: 1,
        name: "Units Per Hour",
        measurementUnit: "7.68 u/h",
        progress: 80,
      },
      {
        id: 2,
        name: "Fluxes Per Hour",
        measurementUnit: "12.7 f/m",
        progress: 60,
      },
      { id: 3, name: "Inclination", measurementUnit: "58.67°", progress: 65 },
      { id: 4, name: "Start Time", measurementUnit: "1m 04s", progress: 10 },
      {
        id: 5,
        name: "Defects Per 100 Unit",
        measurementUnit: "2/100 d",
        progress: 10,
      },
      { id: 6, name: "Energy Class", measurementUnit: "A++", progress: 100 },
    ],
  },
  {
    name: "Fusion 4",
    id: 5,
    img: ProductImage,
    detailImg: DetailImg,
    cells: [
      { id: 1, img: null, name: null },
      { id: 2, img: null, name: null },
      { id: 3, img: null, name: null },
      { id: 3, img: null, name: null },
    ],
    options: [
      {
        id: 1,
        name: "Units Per Hour",
        measurementUnit: "7.68 u/h",
        progress: 80,
      },
      {
        id: 2,
        name: "Fluxes Per Hour",
        measurementUnit: "12.7 f/m",
        progress: 60,
      },
      { id: 3, name: "Inclination", measurementUnit: "58.67°", progress: 65 },
      { id: 4, name: "Start Time", measurementUnit: "1m 04s", progress: 10 },
      {
        id: 5,
        name: "Defects Per 100 Unit",
        measurementUnit: "2/100 d",
        progress: 10,
      },
      { id: 6, name: "Energy Class", measurementUnit: "A++", progress: 100 },
    ],
  },
];

export const PRODUCT_LIST_FROM_BACK = [
  {
    device_id: 5,
    device_name: "Fusion 4",
    cellnum: 4,
    details:
      "This compact modular in-line system ulitises the same design concept as the new generation Synchrodex Pro soldering cell and offers the user significantly reduced process time when compared to using a single soldering unit with on-board fluxing and preheat functions.",
    units_per_hour: "0.00",
    start_time: 0,
    fluxes_per_min: "0.00",
    defects_per_unit: "0.00",
    inclination: "0.00",
    energy_class: "0.00",
    standard_features: [
      {
        name: "Initial Solder Fill 63:37 only",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Accessory Kit",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Standard Production Data Export (CSV File with Configurable Board Process Data)",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Set of AP Solder Tips (2.5mm, 3mm, 3.5mm, 4mm, 5mm, 6mm, 8mm, 10mm & 12mm)",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Lead-Free Capability",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Multilevel Password Security System",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Live data visibility",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Live monitoring with starved system tracking & downtime log",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Per cycle complete program audit record for traceability",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Intelligent comveyor senses and controls speed before stop for board protection",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Automatic board side clamping-programmable standard",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Internal temperature monitoring for homogeneous process",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Machine locked feature-electromagnetic locks-default safe even tools!",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "PillarPAD Offline Programming Package",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Universally Adjustable Tooling Carriers",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Process Viewing Camera",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Solder Wave Height Measurement",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "PillarCOMM.NET Windows Software",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Thermal Nozzle Calibration System using Integrated Setting Camera (requires manual correction)",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Auto Fiducial Correction System",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Colour Programming Camera",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Internal Fume Extraction",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Titanium Drop Jet Fluxer - 200 Micron",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Auto Solder Top-up (Wire Feed) & Solder Level Detect",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Inerted Nitrogen System",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Integral Machine Mounted Monitor and PC",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
    ],
    optional_features: [
      {
        name: "Jet Wave or Custom Nozzles",
        icon: "iconHeater",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Barcode Reader",
        icon: "iconBarcodeReader",
        configured: true,
        description:
          "Allows automatic process program selection and board traceability, both locally and remotely.",
        link: "Enhancements -> Other -> Barcodes and Board Traceability",
      },
      {
        name: "Nitrogen Mass Flow Meter",
        icon: "iconFlowMeter",
        configured: true,
        description: "Measures oxygen level in PPM at the solder nozzle.",
        link: "Enhancements -> Soldering -> Nitrogen Purity Analyser",
      },
      {
        name: "PCB Warpage Correction",
        icon: "iconBoardMaquette",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Additional Flux Head",
        icon: "iconFluxHead",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Closed-Loop Bottom Side Pyro Control",
        icon: "iconTypeCnArrowLoopReverse",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Bottom Side Pre-Heat",
        icon: "iconBottomPreHeat",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Closed-Loop Top Side Pyro Control",
        icon: "iconTypeCnArrowLoop",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Top Side Pre-Heat",
        icon: "iconTopPreHeat",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Electromagnetic Pump Assembly",
        icon: "iconElectromagneticPump",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Fiducial Correction",
        icon: "iconFiducialCorrection",
        configured: false,
        description: "Corrects board positional errors in X, Y and theta.",
        link: "Enhancements -> Other -> Fiducial Correction",
      },
    ],
    other_options: [
      {
        name: "Ring Heater",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Dual Bath",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Optical Flux Presence Sensor",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Flux Flow Monitoring",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "MES (Manufacturing Equipment Systems)",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Extraction Anamometer",
        configured: false,
        description: null,
        link: null,
      },
    ],
  },
  {
    device_id: 4,
    device_name: "Fusion 3",
    cellnum: 3,
    details:
      "This compact modular in-line system ulitises the same design concept as the new generation Synchrodex Pro soldering cell and offers the user significantly reduced process time when compared to using a single soldering unit with on-board fluxing and preheat functions.",
    units_per_hour: "0.00",
    start_time: 0,
    fluxes_per_min: "0.00",
    defects_per_unit: "0.00",
    inclination: "0.00",
    energy_class: "0.00",
    standard_features: [
      {
        name: "Solder Wave Height Measurement",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "PillarCOMM.NET Windows Software",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Thermal Nozzle Calibration System using Integrated Setting Camera (requires manual correction)",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Auto Fiducial Correction System",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Colour Programming Camera",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Internal Fume Extraction",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Titanium Drop Jet Fluxer - 200 Micron",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Auto Solder Top-up (Wire Feed) & Solder Level Detect",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Inerted Nitrogen System",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Integral Machine Mounted Monitor and PC",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Live data visibility",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Live monitoring with starved system tracking & downtime log",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Per cycle complete program audit record for traceability",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Intelligent comveyor senses and controls speed before stop for board protection",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Automatic board side clamping-programmable standard",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Internal temperature monitoring for homogeneous process",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Machine locked feature-electromagnetic locks-default safe even tools!",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Initial Solder Fill 63:37 only",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "PillarPAD Offline Programming Package",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Universally Adjustable Tooling Carriers",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Accessory Kit",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Standard Production Data Export (CSV File with Configurable Board Process Data)",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Set of AP Solder Tips (2.5mm, 3mm, 3.5mm, 4mm, 5mm, 6mm, 8mm, 10mm & 12mm)",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Lead-Free Capability",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Multilevel Password Security System",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Process Viewing Camera",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
    ],
    optional_features: [
      {
        name: "Jet Wave or Custom Nozzles",
        icon: "iconHeater",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Barcode Reader",
        icon: "iconBarcodeReader",
        configured: true,
        description:
          "Allows automatic process program selection and board traceability, both locally and remotely.",
        link: "Enhancements -> Other -> Barcodes and Board Traceability",
      },
      {
        name: "Nitrogen Mass Flow Meter",
        icon: "iconFlowMeter",
        configured: true,
        description: "Measures oxygen level in PPM at the solder nozzle.",
        link: "Enhancements -> Soldering -> Nitrogen Purity Analyser",
      },
      {
        name: "PCB Warpage Correction",
        icon: "iconBoardMaquette",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Additional Flux Head",
        icon: "iconFluxHead",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Closed-Loop Bottom Side Pyro Control",
        icon: "iconTypeCnArrowLoopReverse",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Bottom Side Pre-Heat",
        icon: "iconBottomPreHeat",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Closed-Loop Top Side Pyro Control",
        icon: "iconTypeCnArrowLoop",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Top Side Pre-Heat",
        icon: "iconTopPreHeat",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Electromagnetic Pump Assembly",
        icon: "iconElectromagneticPump",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Fiducial Correction",
        icon: "iconFiducialCorrection",
        configured: false,
        description: "Corrects board positional errors in X, Y and theta.",
        link: "Enhancements -> Other -> Fiducial Correction",
      },
    ],
    other_options: [
      {
        name: "Ring Heater",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Dual Bath",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Optical Flux Presence Sensor",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Flux Flow Monitoring",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "MES (Manufacturing Equipment Systems)",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Extraction Anamometer",
        configured: false,
        description: null,
        link: null,
      },
    ],
  },
  {
    device_id: 3,
    device_name: "Synchrodex Twin Solder",
    cellnum: 2,
    details:
      "This compact modular in-line system ulitises the same design concept as the new generation Synchrodex Pro soldering cell and offers the user significantly reduced process time when compared to using a single soldering unit with on-board fluxing and preheat functions.",
    units_per_hour: "0.00",
    start_time: 0,
    fluxes_per_min: "0.00",
    defects_per_unit: "0.00",
    inclination: "0.00",
    energy_class: "0.00",
    standard_features: [
      {
        name: "Accessory Kit",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Standard Production Data Export (CSV File with Configurable Board Process Data)",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Multilevel Password Security System",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Solder Wave Height Measurement",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Internal Fume Extraction",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Titanium Drop Jet Fluxer - 200 Micron",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Integral Machine Mounted Monitor and PC",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Live data visibility",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Live monitoring with starved system tracking & downtime log",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Per cycle complete program audit record for traceability",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Intelligent comveyor senses and controls speed before stop for board protection",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Automatic board side clamping-programmable standard",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Internal temperature monitoring for homogeneous process",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Machine locked feature-electromagnetic locks-default safe even tools!",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Initial Solder Fill 63:37 only",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Set of AP Solder Tips (2.5mm, 3mm, 3.5mm, 4mm, 5mm, 6mm, 8mm, 10mm & 12mm)",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Lead-Free Capability",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Process Viewing Camera",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "PillarCOMM.NET Windows Software",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Thermal Nozzle Calibration System using Integrated Setting Camera (requires manual correction)",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Auto Fiducial Correction System",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Universally Adjustable Tooling Carriers",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Colour Programming Camera",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Auto Solder Top-up (Wire Feed) & Solder Level Detect",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Inerted Nitrogen System",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "PillarPAD Offline Programming Package",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
    ],
    optional_features: [
      {
        name: "Barcode Reader",
        icon: "iconBarcodeReader",
        configured: true,
        description:
          "Allows automatic process program selection and board traceability, both locally and remotely.",
        link: "Enhancements -> Other -> Barcodes and Board Traceability",
      },
      {
        name: "Fiducial Correction",
        icon: "iconFiducialCorrection",
        configured: true,
        description: "Corrects board positional errors in X, Y and theta.",
        link: "Enhancements -> Other -> Fiducial Correction",
      },
      {
        name: "Additional Flux Head",
        icon: "iconFluxHead",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Closed-Loop Bottom Side Pyro Control",
        icon: "iconTypeCnArrowLoopReverse",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Bottom Side Pre-Heat",
        icon: "iconBottomPreHeat",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Closed-Loop Top Side Pyro Control",
        icon: "iconTypeCnArrowLoop",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Top Side Pre-Heat",
        icon: "iconTopPreHeat",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Jet Wave or Custom Nozzles",
        icon: "iconHeater",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Electromagnetic Pump Assembly",
        icon: "iconElectromagneticPump",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Nitrogen Mass Flow Meter",
        icon: "iconFlowMeter",
        configured: false,
        description: "Measures oxygen level in PPM at the solder nozzle.",
        link: "Enhancements -> Soldering -> Nitrogen Purity Analyser",
      },
      {
        name: "PCB Warpage Correction",
        icon: "iconBoardMaquette",
        configured: false,
        description: null,
        link: null,
      },
    ],
    other_options: [
      {
        name: "Ring Heater",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Dual Bath",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Optical Flux Presence Sensor",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Flux Flow Monitoring",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "MES (Manufacturing Equipment Systems)",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Extraction Anamometer",
        configured: false,
        description: null,
        link: null,
      },
    ],
  },
  {
    device_id: 2,
    device_name: "Synchrodex Single Solder",
    cellnum: 1,
    details:
      "This compact modular in-line system ulitises the same design concept as the new generation Synchrodex Pro soldering cell and offers the user significantly reduced process time when compared to using a single soldering unit with on-board fluxing and preheat functions.",
    units_per_hour: "0.00",
    start_time: 0,
    fluxes_per_min: "0.00",
    defects_per_unit: "0.00",
    inclination: "0.00",
    energy_class: "0.00",
    standard_features: [
      {
        name: "Live data visibility",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Live monitoring with starved system tracking & downtime log",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Per cycle complete program audit record for traceability",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Intelligent comveyor senses and controls speed before stop for board protection",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Automatic board side clamping-programmable standard",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Internal temperature monitoring for homogeneous process",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Machine locked feature-electromagnetic locks-default safe even tools!",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Initial Solder Fill 63:37 only",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Accessory Kit",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Standard Production Data Export (CSV File with Configurable Board Process Data)",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Set of AP Solder Tips (2.5mm, 3mm, 3.5mm, 4mm, 5mm, 6mm, 8mm, 10mm & 12mm)",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Lead-Free Capability",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Multilevel Password Security System",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Process Viewing Camera",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Solder Wave Height Measurement",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "PillarCOMM.NET Windows Software",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Thermal Nozzle Calibration System using Integrated Setting Camera (requires manual correction)",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "PillarPAD Offline Programming Package",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Auto Fiducial Correction System",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Universally Adjustable Tooling Carriers",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Colour Programming Camera",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Internal Fume Extraction",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Titanium Drop Jet Fluxer - 200 Micron",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Auto Solder Top-up (Wire Feed) & Solder Level Detect",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Inerted Nitrogen System",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Integral Machine Mounted Monitor and PC",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
    ],
    optional_features: [
      {
        name: "Jet Wave or Custom Nozzles",
        icon: "iconHeater",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Electromagnetic Pump Assembly",
        icon: "iconElectromagneticPump",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Barcode Reader",
        icon: "iconBarcodeReader",
        configured: false,
        description:
          "Allows automatic process program selection and board traceability, both locally and remotely.",
        link: "Enhancements -> Other -> Barcodes and Board Traceability",
      },
      {
        name: "Nitrogen Mass Flow Meter",
        icon: "iconFlowMeter",
        configured: false,
        description: "Measures oxygen level in PPM at the solder nozzle.",
        link: "Enhancements -> Soldering -> Nitrogen Purity Analyser",
      },
      {
        name: "PCB Warpage Correction",
        icon: "iconBoardMaquette",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Fiducial Correction",
        icon: "iconFiducialCorrection",
        configured: false,
        description: "Corrects board positional errors in X, Y and theta.",
        link: "Enhancements -> Other -> Fiducial Correction",
      },
      {
        name: "Additional Flux Head",
        icon: "iconFluxHead",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Closed-Loop Bottom Side Pyro Control",
        icon: "iconTypeCnArrowLoopReverse",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Bottom Side Pre-Heat",
        icon: "iconBottomPreHeat",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Closed-Loop Top Side Pyro Control",
        icon: "iconTypeCnArrowLoop",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Top Side Pre-Heat",
        icon: "iconTopPreHeat",
        configured: false,
        description: null,
        link: null,
      },
    ],
    other_options: [
      {
        name: "Ring Heater",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Dual Bath",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Optical Flux Presence Sensor",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Flux Flow Monitoring",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "MES (Manufacturing Equipment Systems)",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Extraction Anamometer",
        configured: false,
        description: null,
        link: null,
      },
    ],
  },
  {
    device_id: 1,
    device_name: "Synchrodex Solder",
    cellnum: 1,
    details:
      "This compact modular in-line system ulitises the same design concept as the new generation Synchrodex Pro soldering cell and offers the user significantly reduced process time when compared to using a single soldering unit with on-board fluxing and preheat functions.",
    units_per_hour: "0.00",
    start_time: 0,
    fluxes_per_min: "0.00",
    defects_per_unit: "0.00",
    inclination: "0.00",
    energy_class: "0.00",
    standard_features: [
      {
        name: "Live data visibility",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Live monitoring with starved system tracking & downtime log",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Per cycle complete program audit record for traceability",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Intelligent comveyor senses and controls speed before stop for board protection",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Automatic board side clamping-programmable standard",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Internal temperature monitoring for homogeneous process",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Machine locked feature-electromagnetic locks-default safe even tools!",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Initial Solder Fill 63:37 only",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Accessory Kit",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Standard Production Data Export (CSV File with Configurable Board Process Data)",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Set of AP Solder Tips (2.5mm, 3mm, 3.5mm, 4mm, 5mm, 6mm, 8mm, 10mm & 12mm)",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Lead-Free Capability",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Multilevel Password Security System",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Process Viewing Camera",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Solder Wave Height Measurement",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "PillarCOMM.NET Windows Software",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Thermal Nozzle Calibration System using Integrated Setting Camera (requires manual correction)",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "PillarPAD Offline Programming Package",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Auto Fiducial Correction System",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Colour Programming Camera",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Internal Fume Extraction",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Titanium Drop Jet Fluxer - 200 Micron",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Auto Solder Top-up (Wire Feed) & Solder Level Detect",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Inerted Nitrogen System",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Integral Machine Mounted Monitor and PC",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Universally Adjustable Tooling Carriers",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
    ],
    optional_features: [
      {
        name: "Jet Wave or Custom Nozzles",
        icon: "iconHeater",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Electromagnetic Pump Assembly",
        icon: "iconElectromagneticPump",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Barcode Reader",
        icon: "iconBarcodeReader",
        configured: true,
        description:
          "Allows automatic process program selection and board traceability, both locally and remotely.",
        link: "Enhancements -> Other -> Barcodes and Board Traceability",
      },
      {
        name: "Nitrogen Mass Flow Meter",
        icon: "iconFlowMeter",
        configured: true,
        description: "Measures oxygen level in PPM at the solder nozzle.",
        link: "Enhancements -> Soldering -> Nitrogen Purity Analyser",
      },
      {
        name: "PCB Warpage Correction",
        icon: "iconBoardMaquette",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Fiducial Correction",
        icon: "iconFiducialCorrection",
        configured: true,
        description: "Corrects board positional errors in X, Y and theta.",
        link: "Enhancements -> Other -> Fiducial Correction",
      },
      {
        name: "Additional Flux Head",
        icon: "iconFluxHead",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Closed-Loop Bottom Side Pyro Control",
        icon: "iconTypeCnArrowLoopReverse",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Bottom Side Pre-Heat",
        icon: "iconBottomPreHeat",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Closed-Loop Top Side Pyro Control",
        icon: "iconTypeCnArrowLoop",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Top Side Pre-Heat",
        icon: "iconTopPreHeat",
        configured: true,
        description: null,
        link: null,
      },
    ],
    other_options: [
      {
        name: "Ring Heater",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Dual Bath",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Optical Flux Presence Sensor",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Flux Flow Monitoring",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "MES (Manufacturing Equipment Systems)",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Extraction Anamometer",
        configured: false,
        description: null,
        link: null,
      },
    ],
  },
];
