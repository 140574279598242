import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/system";
import styles from "./VerticalCircularSteps.module.scss";

const StepContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const StepButton = styled(IconButton)(({ theme, active }) => ({
  color: active ? "#A30A2A" : "#E4C9C9",
  border: "4px solid",
  borderColor: active ? "#A30A2A" : "#E4C9C9",
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

const Line = styled("div")(({ theme, active }) => ({
  height: 40,
  width: 4,
  backgroundColor: active ? "#A30A2A" : "#E4C9C9",
}));

const scrollToElement = (elementId, callback) => {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView({ behavior: "smooth", block: "start" });
    setTimeout(callback, 500);
  }
};

export const VerticalCircularSteps = ({
  currentStep,
  steps,
  handleClickStep,
}) => {
  const handleClick = step => {
    const { id, number } = step;
    scrollToElement(id, () => handleClickStep(number));
  };
  return (
    <StepContainer>
      {steps.map((step, index) => (
        <div
          className={styles.pointContainer}
          data-section={step.number}
          key={index}
        >
          <StepButton
            active={currentStep >= step.number}
            onClick={() => handleClick(step)}
            disableRipple
          >
            {step.icon}
          </StepButton>
          {index < steps.length - 1 && (
            <Line active={currentStep >= step.number + 1} />
          )}
        </div>
      ))}
    </StepContainer>
  );
};
