import styles from "./Button.module.scss";

export const Button = ({
  children,
  type = "primary",
  size = "m",
  icon,
  bold,
  danger,
  disabled,
  onClick,
  className,
  ...props
}) => {
  const classNames = `${styles.button} ${styles[type]} ${styles[size]} ${
    !!icon && styles.withIcon
  } ${!!bold && styles.textBold} ${!!danger && styles.danger} ${
    disabled && styles.disabled
  } ${className}`;
  return (
    <button
      onClick={!disabled ? onClick : undefined}
      className={classNames}
      {...props}
    >
      {!!icon && icon}
      {children}
    </button>
  );
};
