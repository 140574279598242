import { useEffect } from "react";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import configuratorReducer, {
  updatePersistKey,
} from "./slices/configuratorSlice";
import companyReducer from "./slices/companySlice";

const createPersistConfig = routeName => ({
  key: `configurator-${routeName}`,
  storage: storage,
});

const createRootReducer = routeName => {
  const configuratorPersistConfig = createPersistConfig(routeName);

  return combineReducers({
    configuratorReducer: persistReducer(
      configuratorPersistConfig,
      configuratorReducer
    ),
    companyReducer: companyReducer,
  });
};

export function useStore() {
  const rootReducer = createRootReducer(getRouteName());

  const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [
            "persist/PERSIST",
            "persist/REHYDRATE",
            "persist/PAUSE",
            "persist/PAUSE",
            "persist/PURGE",
            "persist/REGISTER",
          ],
        },
      }),
  });

  const persistor = persistStore(store);

  useEffect(() => {
    const handleHashChange = () => {
      const routeName = getRouteName();
      store.dispatch(updatePersistKey());
      store.replaceReducer(createRootReducer(routeName));
      persistor.persist();
    };

    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, [store, persistor]);

  return { store, persistor };
}

const getRouteName = () => {
  const isHashRouting = window.location.hash.length > 0;
  return isHashRouting
    ? window.location.hash?.split("/")?.filter(str => str)?.[1] || "default"
    : window.location.pathname.replace(/\//g, "") || "default";
};
