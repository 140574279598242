export const BANNER_DETAIL_PARAMS = {
  product: [
    { value: "800+", desc: "Installations" },
    { value: "4.5k+", desc: "Worked Hours" },
    { value: "2k+", desc: "Soldered Units" },
  ],
  market: [
    { value: "40+", desc: "Installations" },
    { value: "97%", desc: "Effectiveness" },
    { value: "2k+", desc: "Market Share" },
  ],
};

export const BANNER_TITLES = {
  product: "Orissa Synchrodex Pro Combo",
  market: "Electric Vehicles",
};

export const DEFAULT_PROD_NAME = "Orissa Synchrodex Pro Combo";
export const DEFAULT_MARKET_SECTOR_TITLE = "Electric Vehicles";
