import UnmarkedCarMechanicsImg from "assets/images/market-sector/unmarked-car-mechanics.png";
import SwitchesSensorsMotorsImg from "assets/images/market-sector/switches-sensors-motors.png";
import PowerConstructionUnitImg from "assets/images/market-sector/power-construction-unit.png";
import VariousPCBImg from "assets/images/market-sector/various-PCB.png";
import DashboardImg from "assets/images/market-sector/dashboard.png";
import TirePressureMonitorImg from "assets/images/market-sector/tire-pressure-monitor.png";

export const CHARACTERISTICS = {
  default: [
    {
      section: "",
      title: "Built for Safety",
      paragraphs: [
        "Battery Management Systems: Ensure optimal performance and longevity with our precise soldering for BMS components.",
        "Power Electronics: Trust us for reliable soldering solutions for power electronic systems in EVs.",
        "Motor Controllers: Maximize efficiency and performance with our expert soldering for motor control units.",
        "Charging Systems: From connectors to circuit boards, we handle all aspects of soldering for EV charging systems.",
      ],
      type: "image",
      content: UnmarkedCarMechanicsImg,
    },
    {
      section: "Main Construction Unit",
      title: "Built for Safety",
      paragraphs: [
        "Battery Management Systems: Ensure optimal performance and longevity with our precise soldering for BMS components.",
        "Power Electronics: Trust us for reliable soldering solutions for power electronic systems in EVs.",
        "Motor Controllers: Maximize efficiency and performance with our expert soldering for motor control units.",
        "Charging Systems: From connectors to circuit boards, we handle all aspects of soldering for EV charging systems.",
      ],
      type: "image",
      content: PowerConstructionUnitImg,
    },
    {
      section: "Various PCB",
      title: "Built for Safety",
      paragraphs: [
        "Battery Management Systems: Ensure optimal performance and longevity with our precise soldering for BMS components.",
        "Power Electronics: Trust us for reliable soldering solutions for power electronic systems in EVs.",
        "Motor Controllers: Maximize efficiency and performance with our expert soldering for motor control units.",
        "Charging Systems: From connectors to circuit boards, we handle all aspects of soldering for EV charging systems.",
      ],
      type: "image",
      content: VariousPCBImg,
    },
    {
      section: "Dashboard",
      title: "Built for Safety",
      paragraphs: [
        "Battery Management Systems: Ensure optimal performance and longevity with our precise soldering for BMS components.",
        "Power Electronics: Trust us for reliable soldering solutions for power electronic systems in EVs.",
        "Motor Controllers: Maximize efficiency and performance with our expert soldering for motor control units.",
        "Charging Systems: From connectors to circuit boards, we handle all aspects of soldering for EV charging systems.",
      ],
      type: "image",
      content: DashboardImg,
    },
    {
      section: "Tire Pressure Monitor",
      title: "Built for Safety",
      paragraphs: [
        "Battery Management Systems: Ensure optimal performance and longevity with our precise soldering for BMS components.",
        "Power Electronics: Trust us for reliable soldering solutions for power electronic systems in EVs.",
        "Motor Controllers: Maximize efficiency and performance with our expert soldering for motor control units.",
        "Charging Systems: From connectors to circuit boards, we handle all aspects of soldering for EV charging systems.",
      ],
      type: "image",
      content: TirePressureMonitorImg,
    },
    {
      section: "Switches, sensors, motors",
      title: "Built for Safety",
      paragraphs: [
        "Battery Management Systems: Ensure optimal performance and longevity with our precise soldering for BMS components.",
        "Power Electronics: Trust us for reliable soldering solutions for power electronic systems in EVs.",
        "Motor Controllers: Maximize efficiency and performance with our expert soldering for motor control units.",
        "Charging Systems: From connectors to circuit boards, we handle all aspects of soldering for EV charging systems.",
      ],
      type: "image",
      content: SwitchesSensorsMotorsImg,
    },
  ],
  "Automotive/EV": [
    {
      section: "",
      title: "Automotive/EV  PCB and Power Electronics",
      paragraphs: [
        "Revolutionize your EV and automotive manufacturing with Pillarhouse's advanced selective soldering systems. Engineered for high throughput and precision, our comprehensive lineup delivers unmatched performance.",
        "From integrated fully closed-loop IR technology to the high speed full convection , our automtive specialized systems are high weight carrying and thermal mass accommodating.",
        "Discover the unparalleled efficiency of our unique Jetwave and 1.5 micro thermal nozzle designs for exceptional high-density, high thermal mass production. Elevate your manufacturing prowess with Pillarhouse today.",
      ],
      type: "image",
      content: UnmarkedCarMechanicsImg,
    },
    {
      section: "Power Conversion Unit",
      title: "Main Construction Unit",
      paragraphs: [
        "40kg capable roller conveyors, combination high watt density closed loop IR and full convection heat, pcb/module size of up to 24” x 48”, special high flow nozzles for chokes, thransformers and high thermal mass devices",
      ],
      type: "image",
      content: PowerConstructionUnitImg,
    },
    {
      section: "Various PCB",
      title: "ECU",
      paragraphs: [
        " High speed modular or intergated approach for highest throughput efficiency, warpage correction for panelized/pre-routed/scored panels, jetwave specifically for high pin count ecu connectors",
      ],
      type: "image",
      content: VariousPCBImg,
    },
    {
      section: "Dashboard",
      title: "Standard JJ! size",
      paragraphs: [
        "Special nozzle design for headers,steppers, lcd connections, additive modules for volume growth without retooling.",
      ],
      type: "image",
      content: DashboardImg,
    },
    {
      section: "Tire Pressure Monitor",
      title: "TPMS",
      paragraphs: [
        "Bottom side heat to heat imbedded pcbs, flux volume calculation or measurement to avoid TPMS topside unactivated flux*, warpage correction, multi head soldering for speed and control.",
      ],
      type: "image",
      content: TirePressureMonitorImg,
    },
    {
      section: "Switches, sensors, motors",
      title: "Window Switch/Lock Switch/Mirror control",
      paragraphs: [
        "Revolutionize your EV and automotive manufacturing with Pillarhouse's advanced selective soldering systems. Engineered for high throughput and precision, our comprehensive lineup delivers unmatched performance.",
        "From integrated fully closed-loop IR technology to the high speed full convection , our automtive specialized systems are high weight carrying and thermal mass accommodating.",
        "Discover the unparalleled efficiency of our unique Jetwave and 1.5 micro thermal nozzle designs for exceptional high-density, high thermal mass production. Elevate your manufacturing prowess with Pillarhouse today.",
      ],
      type: "image",
      content: SwitchesSensorsMotorsImg,
    },
  ],
};
