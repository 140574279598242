import React from "react";
import TextField from "@mui/material/TextField";
import styles from "./TextArea.module.scss";

export const TextArea = ({ styleType = "dark", size = "md", ...props }) => {
  return (
    <TextField
      multiline
      variant="outlined"
      className={`${styles.textArea} ${styles[styleType]} ${styles[size]}`}
      {...props}
    />
  );
};
