import { useTranslation } from "hooks/useTranslation";
import { DynamicLink } from "../DynamicLink";
import styles from "./OtherServiceItem.module.scss";

export const OtherServiceItem = ({ content, name, description, forwardTo }) => {
  const { translate } = useTranslation();
  return (
    <DynamicLink to={forwardTo} className={styles.container}>
      <div className={styles.imgBlock}>
        <img alt={name} src={content} />
      </div>
      <div className={styles.textBlock}>
        <h6 className={styles.name}>{translate(name)}</h6>
        <p className={styles.desc}>{translate(description)}</p>
      </div>
    </DynamicLink>
  );
};
