import { Popover } from "@mui/material";
import styles from "./MainPopover.module.scss";

export const MainPopover = props => {
  const {
    anchorVertical = "center",
    anchorHorizontal = "center",
    transformVertical = "bottom",
    transformHorizontal = "left",
    className = "",
    anchorEl,
    open,
    onClose,
    children,
    popoverRef,
  } = props;
  return (
    <Popover
      PaperProps={{
        sx: {
          borderRadius: "8px",
        },
      }}
      ref={popoverRef}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      className={`${styles.container} ${className}`}
      anchorOrigin={{
        vertical: anchorVertical,
        horizontal: anchorHorizontal,
      }}
      transformOrigin={{
        vertical: transformVertical,
        horizontal: transformHorizontal,
      }}
    >
      {children}
    </Popover>
  );
};
