import styles from "./LocationCard.module.scss";
import {
  IconLocationOutline,
  IconUserRoundOutline,
  IconPhoneOutline,
  IconPrinterOutline,
  IconGlobeOutline,
  IconEmailOutline,
} from "icons";

export const LocationCard = ({
  image,
  name,
  address,
  representative,
  phone,
  fax,
  website,
  email,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <img src={image} alt={name} />
      </div>
      <h6 className={styles.spaceName}>{name}</h6>
      <div className={styles.row}>
        <IconLocationOutline />
        <p className={styles.text}>{address}</p>
      </div>
      <div className={styles.row}>
        <IconUserRoundOutline />
        <p className={styles.text}>{representative}</p>
      </div>
      <div className={styles.row}>
        <IconPhoneOutline />
        <p className={styles.text}>{phone}</p>
      </div>
      <div className={styles.row}>
        <IconPrinterOutline />
        <p className={styles.text}>{fax}</p>
      </div>
      <div className={styles.row}>
        <IconGlobeOutline />
        <p className={styles.text}>{website}</p>
      </div>
      <div className={styles.row}>
        <IconEmailOutline />
        <p className={styles.text}>{email}</p>
      </div>
    </div>
  );
};
