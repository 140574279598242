import { Link } from "react-router-dom";
import { LanguageSelect } from "shared/components/LanguageSelect";
import styles from "./Footer.module.scss";
import RedLogo from "assets/logos/red-logo.png";

export const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className={styles.container}>
      <div className={styles.generalFootBlock}>
        <Link to="/" className={styles.logo}>
          <img src={RedLogo} alt="Pillarhouse" />
        </Link>
        <div className={styles.rightsReserved}>
          © {currentYear} Pillarhouse International • All Rights Reserved
        </div>
        <LanguageSelect />
      </div>
    </div>
  );
};
