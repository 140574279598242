export const constructPdfExportBody = data => {
  let res;
  const products = data.chosenProducts.map(prod => {
    const cells = prod.cells
      .map(cell => cell?.backend_name)
      ?.filter(item => !!item);
    return { ...prod.product, cells };
  });

  res = products.filter(p => p?.id);

  return res;
};
