import { useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { Banner } from "./Banner";
import { BANNER_DETAIL_PARAMS, BANNER_TITLES } from "./Header/constants";
import { ScrollToTopButton } from "shared/components/ScrollToTopButton";
import { Configurator } from "Configurator";
import { useSelector } from "react-redux";
import { onlyConfigProductsSelector } from "reduxStore/slices/selectors";
import { PRODUCT_REGULAR_NAME_BY_CONFIG_NAME } from "constants";

export const MainLayout = ({ isScrolled }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpenConfigurator, setIsOpenConfigurator] = useState(false);
  const { search, pathname } = location;
  const queryParams = new URLSearchParams(search);
  const name = queryParams.get("name");
  const isProductPage = pathname.includes("/product");
  const isMarketSector = pathname.includes("/market");
  const bannerDetailKey = isProductPage ? "product" : "market";
  const bannerDetailParams = BANNER_DETAIL_PARAMS[bannerDetailKey];

  const onlyConfigProducts = useSelector(onlyConfigProductsSelector);

  const currentProduct = onlyConfigProducts.find(prod => {
    return prod.device_name === name;
  });

  const handleClickOptionShowMore = linkData => {
    navigate(linkData, { state: linkData.state });
    setIsOpenConfigurator(false);
    const configuratorContentElm = document.getElementById(
      "configurator-inner-content"
    );
    if (configuratorContentElm) configuratorContentElm.scrollTop = 0;
  };

  return (
    <>
      <Header isScrolled={isScrolled} />
      <Banner
        isMarketSector={isMarketSector}
        isOpenConfigurator={isOpenConfigurator}
        setIsOpenConfigurator={setIsOpenConfigurator}
        currentProduct={currentProduct === -1 ? null : currentProduct}
        bannerDetailKey={bannerDetailKey}
        detailParameters={bannerDetailParams}
        title={name || BANNER_TITLES[bannerDetailKey]}
      />
      <Configurator
        handleClickOptionShowMore={handleClickOptionShowMore}
        isOpen={isOpenConfigurator}
        setIsOpen={setIsOpenConfigurator}
      />
      <Outlet
        currentProduct={currentProduct === -1 ? null : currentProduct}
        name={name}
      />
      <Footer />
      <ScrollToTopButton isScrolled={isScrolled} />
    </>
  );
};
