import React, { useEffect, useRef, useState } from "react";
import styles from "./DropdownItem.module.scss";
import { IconChevronDown } from "icons";

const DropdownItem = ({
  label,
  hideArrow,
  hasContentLeftPadding,
  children,
  number,
  isOpen,
  setIsOpen,
}) => {
  const [paddingLeft, setPaddingLeft] = useState(0);
  const buttonRef = useRef(null);
  const openTimeoutRef = useRef(null);
  const closeTimeoutRef = useRef(null);

  const handleOpen = () => {
    clearTimeout(closeTimeoutRef.current);
    openTimeoutRef.current = setTimeout(() => {
      setIsOpen(number);
    }, 200);
  };

  const handleClose = () => {
    clearTimeout(openTimeoutRef.current);
    closeTimeoutRef.current = setTimeout(() => {
      setIsOpen(null);
    }, 200);
  };

  useEffect(() => {
    if (isOpen && buttonRef.current) {
      const { left } = buttonRef.current.getBoundingClientRect();
      setPaddingLeft(left - 20);
    }

    return () => {
      clearTimeout(openTimeoutRef.current);
      clearTimeout(closeTimeoutRef.current);
    };
  }, [isOpen]);

  return (
    <div
      onMouseOver={() => {
        clearTimeout(closeTimeoutRef.current);
        handleOpen();
      }}
      onMouseLeave={() => {
        clearTimeout(openTimeoutRef.current);
        handleClose();
      }}
      className={styles.dropdownItem}
    >
      <button
        ref={buttonRef}
        className={`${styles.dropdownButton} ${isOpen && styles.active}`}
      >
        {label}
        {!hideArrow && (
          <span className={`${styles.icon} ${isOpen ? styles.iconOpen : ""}`}>
            <IconChevronDown color={isOpen ? "#DC2324" : "#fff"} />
          </span>
        )}
      </button>
      {isOpen && (
        <div
          className={`${styles.dropdownContent} ${
            hasContentLeftPadding && styles.hasLeftPadding
          }`}
          style={{ paddingLeft: hasContentLeftPadding && `${paddingLeft}px` }}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default DropdownItem;
