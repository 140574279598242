import { useTranslation } from "hooks/useTranslation";
import { DynamicLink } from "shared/components/DynamicLink";
import styles from "./ProductDropdownContent.module.scss";

export const ProductsDropdownContent = ({ closeDropdown }) => {
  const { translate } = useTranslation();
  const productLinks = {
    1: [
      { name: translate("solder bath") },
      { name: translate("solder pumps") },
    ],
    2: [
      { name: translate("control") },
      { name: translate("monitoring") },
      { name: translate("inspection") },
    ],
    3: [
      { name: translate("handload"), highlighted: true },
      { name: translate("pilot") },
      { name: translate("Jade MK I") },
      { name: translate("Jade MK IV") },
      { name: translate("Jade Handex") },
      { name: translate("Jade Pro"), highlighted: true },
      { name: translate("Jade Pro Layouts") },
      { name: translate("Jade Prodex"), highlighted: true },
      { name: translate("Jade Prodex Layouts") },
    ],
    4: [
      { name: translate("In - Line"), highlighted: true },
      { name: translate("Orissa Synchrodex") },
      { name: translate("Orissa Synchrodex Combo") },
      { name: translate("Orissa Synchrodex Pro") },
      { name: translate("Orissa Synchrodex Pro Combo") },
      { name: translate("Orissa Fusion"), highlighted: true },
      { name: translate("Fusion Layouts") },
    ],
    5: [
      { name: translate("Coil Winding"), highlighted: true },
      { name: translate("Quadron") },
      { name: translate("Flux Density Controller") },
    ],
    6: [
      { name: translate("Other Equipment"), highlighted: true },
      { name: translate("Pillar GEN") },
      { name: translate("Pillar DOT") },
      { name: translate("Pillar Sonic") },
    ],
    7: [
      { name: translate("Machine Comparison"), highlighted: true },
      { name: translate("Handload Comparison") },
      { name: translate("In - Line Comparison") },
    ],
  };

  return (
    <div className={styles.container}>
      <div className={styles.column}>
        <div className={styles.titleBlock}>
          <h3 className={styles.title}>{translate("soldering")}</h3>
        </div>
        <div className={styles.contentBlock}>
          <div className={styles.column}>
            {productLinks[1].map((pl, idx) => (
              <DynamicLink
                onClick={closeDropdown}
                key={idx}
                queryParams={{ name: pl.name }}
                to="/product"
                disables={pl.highlighted}
                className={`${styles.subTitle} ${
                  pl.highlighted && styles.highlighted
                }`}
              >
                {pl.name}
              </DynamicLink>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.column}>
        <div className={styles.titleBlock}>
          <h3 className={styles.title}>{translate("software")}</h3>
        </div>
        <div className={styles.contentBlock}>
          <div className={styles.column}>
            {productLinks[2].map((pl, idx) => (
              <DynamicLink
                onClick={closeDropdown}
                key={idx}
                queryParams={{ name: pl.name }}
                to="/product"
                disabled={pl.highlighted}
                className={`${styles.subTitle} ${
                  pl.highlighted && styles.highlighted
                }`}
              >
                {pl.name}
              </DynamicLink>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.column}>
        <div className={styles.titleBlock}>
          <h3 className={styles.title}>{translate("machines")}</h3>
        </div>
        <div className={styles.contentBlock}>
          <div className={styles.column}>
            {productLinks[3].map((pl, idx) => (
              <DynamicLink
                onClick={closeDropdown}
                key={idx}
                queryParams={{ name: pl.name }}
                to="/product"
                disabled={pl.highlighted}
                className={`${styles.subTitle} ${
                  pl.highlighted && styles.highlighted
                }`}
              >
                {pl.name}
              </DynamicLink>
            ))}
          </div>
          <div className={styles.column}>
            {productLinks[4].map((pl, idx) => (
              <DynamicLink
                onClick={closeDropdown}
                key={idx}
                queryParams={{ name: pl.name }}
                to="/product"
                className={`${styles.subTitle} ${
                  pl.highlighted && styles.highlighted
                }`}
              >
                {pl.name}
              </DynamicLink>
            ))}
          </div>
          <div className={styles.column}>
            {productLinks[5].map((pl, idx) => (
              <DynamicLink
                onClick={closeDropdown}
                key={idx}
                queryParams={{ name: pl.name }}
                to="/product"
                className={`${styles.subTitle} ${
                  pl.highlighted && styles.highlighted
                }`}
              >
                {pl.name}
              </DynamicLink>
            ))}
          </div>
          <div className={styles.column}>
            {productLinks[6].map((pl, idx) => (
              <DynamicLink
                onClick={closeDropdown}
                key={idx}
                queryParams={{ name: pl.name }}
                to="/product"
                className={`${styles.subTitle} ${
                  pl.highlighted && styles.highlighted
                }`}
              >
                {pl.name}
              </DynamicLink>
            ))}
          </div>
          <div className={styles.column}>
            {productLinks[7].map((pl, idx) => (
              <DynamicLink
                onClick={closeDropdown}
                key={idx}
                queryParams={{ name: pl.name }}
                to="/product"
                className={`${styles.subTitle} ${
                  pl.highlighted && styles.highlighted
                }`}
              >
                {pl.name}
              </DynamicLink>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
