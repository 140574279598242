import { useState } from "react";
import { Snackbar, Portal } from "@mui/material";
import { Button } from "../Button";
import { useTranslation } from "hooks/useTranslation";
import styles from "./AssetDownloadBlock.module.scss";
import { IconArrowDownToLine, IconCopy } from "icons";

export const AssetDownloadBlock = ({ children, link }) => {
  const { translate } = useTranslation();

  const [isCopied, setIsCopied] = useState();

  const handleDownload = () => {
    const element = document.createElement("a");
    element.href = link;
    element.download = link.split("/").pop();
    element.target = "_blank";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const handleCopyLink = () => {
    const element = document.createElement("a");
    element.href = link;
    document.body.appendChild(element);
    const absoluteUrl = element.href;
    document.body.removeChild(element);

    navigator.clipboard
      .writeText(absoluteUrl)
      .then(() => setIsCopied(true))
      .catch(err => console.error("Failed to copy link: ", err));
  };

  return (
    <>
      <Portal>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={isCopied}
          onClose={() => setIsCopied(false)}
          autoHideDuration={3000}
          message={translate("Link copied to clipboard!")}
          key={"Copy Link"}
        />
      </Portal>
      <div className={styles.container}>
        <div className={styles.btnsBlock}>
          <Button
            type="secondary"
            icon={<IconArrowDownToLine color="#005572" />}
            onClick={handleDownload}
          >
            {translate("Download")}
          </Button>
          <Button
            type="secondary"
            icon={<IconCopy color="#005572" />}
            onClick={handleCopyLink}
          >
            {translate("Copy Link")}
          </Button>
        </div>
        {children}
      </div>
    </>
  );
};
