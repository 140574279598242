import en from "../locales/en.json";
import am from "../locales/am.json";

const locales = {
  en,
  am,
};

export const useTranslation = () => {
  const selectedLanguage = localStorage.getItem("selectedLanguage") || "en";

  const translate = key => {
    const translationSet = locales[selectedLanguage] || {};
    return translationSet.hasOwnProperty(key) ? translationSet[key] : en[key];
  };

  return { translate };
};
