import { forwardRef } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import { Button } from "../Button";
import styles from "./ConfirmDialog.module.scss";
import { IconClose } from "icons";

export const ConfirmDialog = forwardRef((props, ref) => {
  const {
    open,
    handleClose,
    handleConfirm,
    withCloseIcon,
    title = "",
    description = "",
    declineBtnText = "Cancel",
    confirmBtnText = "Reset",
    size = "md",
    type = "primary",
  } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down(size));

  return (
    <Dialog
      ref={ref}
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      PaperProps={{
        className: `${styles[size]} ${styles[type]}`,
      }}
    >
      <DialogTitle className={styles.title} id="responsive-dialog-title">
        {title}
        {!!withCloseIcon && (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: theme.spacing(1),
              top: theme.spacing(1),
              color: theme.palette.grey[500],
            }}
          >
            <IconClose />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={styles.description}>
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={`${size === "s" && styles.actionsBlock}`}>
        <Button type="outlined" danger onClick={handleClose}>
          {declineBtnText}
        </Button>
        <Button type="primary" onClick={handleConfirm}>
          {confirmBtnText}
        </Button>
      </DialogActions>
    </Dialog>
  );
});
