import React, { useCallback, useEffect, useRef, useState } from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const StyledTooltip = styled(
  ({
    className,
    color = "black",
    fontSize = "14px",
    fontWeight = 400,
    padding = "12px 16px",
    borderRadius,
    maxWidth,
    minWidth,
    ...props
  }) => <Tooltip {...props} arrow classes={{ popper: className }} />
)(
  ({
    theme,
    color,
    fontSize,
    fontWeight,
    padding,
    borderRadius,
    maxWidth,
    minWidth,
  }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common[color] || color,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common[color] || color,
      padding,
      fontSize,
      fontWeight,
      borderRadius,
      width: "auto",
      maxWidth: maxWidth || "none", // Allows dynamic setting
      minWidth: minWidth || "auto",
    },
  })
);

export const MainTooltip = ({
  children,
  title,
  placement = "top",
  enterDelay = 0,
  leaveDelay = 0,
  color,
  fontSize,
  fontWeight,
  padding,
  borderRadius,
  maxWidth,
  minWidth,
}) => {
  const [open, setOpen] = useState(false);
  const requestRef = useRef();

  const handleToggle = useCallback(isOpen => {
    if (requestRef.current) {
      cancelAnimationFrame(requestRef.current);
    }
    requestRef.current = requestAnimationFrame(() => {
      setOpen(isOpen);
    });
  }, []);

  useEffect(() => {
    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, []);
  return (
    <StyledTooltip
      title={title}
      placement={placement}
      enterDelay={enterDelay}
      leaveDelay={leaveDelay}
      color={color}
      fontSize={fontSize}
      fontWeight={fontWeight}
      padding={padding}
      borderRadius={borderRadius}
      maxWidth={maxWidth}
      minWidth={minWidth}
      open={open}
      onOpen={() => handleToggle(true)}
      onClose={() => handleToggle(false)}
    >
      {children}
    </StyledTooltip>
  );
};
