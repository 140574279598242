import { useTranslation } from "hooks/useTranslation";
import styles from "./FeatureItem.module.scss";
import { IconCurrentLocation } from "icons";

export const FeatureItem = ({ text, xPos, yPos }) => {
  const { translate } = useTranslation();
  return (
    <div style={{ left: xPos, top: yPos }} className={styles.container}>
      <IconCurrentLocation />
      <p className={styles.text}>{translate(text)}</p>
    </div>
  );
};
