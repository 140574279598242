import React, { forwardRef, useRef } from "react";
import { Swiper } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

export const MainSwiper = forwardRef(({ children, ...props }, ref) => {
  const internalRef = useRef(null);
  const swiperRef = ref || internalRef;

  const handleClick = () => {
    if (swiperRef.current && swiperRef.current.autoplay.running) {
      swiperRef.current.autoplay.stop();
    }
  };

  return (
    <Swiper
      modules={[Autoplay, Pagination]}
      onSwiper={swiper => (swiperRef.current = swiper)}
      onClick={handleClick}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      {...props}
    >
      {children}
    </Swiper>
  );
});
