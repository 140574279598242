import { useTranslation } from "hooks/useTranslation";
import { Button } from "shared/components/Button";
import styles from "./AboutUs.module.scss";
import { IconDarkClose } from "icons";

export const AboutUs = ({ hasCloseIcon, handleClose }) => {
  const { translate } = useTranslation();
  return (
    <div className={styles.container}>
      {!!hasCloseIcon && (
        <div onClick={handleClose} className={styles.closeIconContainer}>
          <IconDarkClose />
        </div>
      )}
      <h4 className={styles.title}>{translate("About Us")}</h4>
      <div className={styles.descriptionContent}>
        <p className={styles.description}>
          {translate(
            "Pillarhouse was founded in 1978, by current Chairman Alex Ciniglio. Pillarhouse is a company with a strong bias towards innovative design and development. Supplying specialist equipment to the coil winding industry. Pillarhouse produced its first production soldering system in 1984. With continued growth throughout the 1980’s, Pillarhouse introduced the World’s first selective soldering machine in 1994. Pillarhouse has nearly 40 years' experience in the design and manufacturing of selective soldering and coil winding equipment for the electronics and PCB related industries. Applications within automotive, telecommunications, consumer, medical, aerospace and military industries. Extensive industry experience  offers  the latest developments in soldering technology backed by a global network of Pillarhouse appointed technical sales and process support specialists. Commitment to engineering excellence with a strong research and development base will ensure that Pillarhouse remains and the forefront of soldering technology."
          )}
        </p>
        <p className={styles.description}>
          {translate(
            "Our philosophy is to produce high engineering standard products with a focus on innovation and cutting edge development. This practice has led to Pillarhouse holding several important patents that have been key to our continued success over many years."
          )}
        </p>
      </div>
      <Button onClick={handleClose} size="m">
        {translate("Ok")}
      </Button>
    </div>
  );
};
