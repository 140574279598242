/**
 * @param {string} url The URL of the file to download.
 * @param {string} filename The name of the file to save as.
 */
export async function downloadFile(url, filename = "download") {
  try {
    const response = await fetch(url);
    if (response.ok) {
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    } else {
      throw new Error("Failed to download file");
    }
  } catch (error) {
    console.error("Error downloading the file:", error);
  }
}
