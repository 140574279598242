export const chosenProductsSelector = state =>
  state.configuratorReducer.chosenProducts;

export const productsListSelector = state =>
  state.configuratorReducer.productsList;

export const onlyConfigProductsSelector = state =>
  state.configuratorReducer.onlyConfigProducts;

export const cellsListSelector = state => state.configuratorReducer.cellsList;

export const selectedLineItemSelector = state =>
  state.configuratorReducer.selectedLineItem;

export const selectedCellToViewSelector = state =>
  state.configuratorReducer.selectedCellToView;

export const configuratorReducerSelector = state => state.configuratorReducer;

export const companiesListSelector = state =>
  state.companyReducer.companiesList;

export const selectedCompanySelector = state =>
  state.companyReducer.selectedCompany;
